// WARNING: DO NOT UPDATE ORDER OF BELOW LINES

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("@rails/actioncable")
require("channels")

import { Turbo } from '@hotwired/turbo-rails'
window.Turbo = Turbo

var jQuery = require('jquery')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

var moment = require('moment')
global.moment = moment;
window.moment = moment;

import common from 'src/common'
window.common = common;

import dragula from 'dragula'
window.dragula = dragula;

import "../styles/application.scss"
require.context('../images', true)

import "bootstrap-datepicker"
import "jquery-toast-plugin"


require("src/simplebar.min.js")
require("packs/jquery.titlealert.min.js")
import * as bootstrap from "bootstrap"
window.bootstrap = bootstrap

import 'select2'
require("src/app.js")

import Tribute from "tributejs"
window.Tribute = Tribute;

import mention from 'src/user_mention'
window.mention = mention;

import { EmojiButton } from '@joeattardi/emoji-button';
window.EmojiButton = EmojiButton;

import intercom from 'src/intercom_messanger'
window.intercom = intercom;


import "controllers"

$.App.init();

// The SW will only work under HTTPS or at localhost.
require ("src/serviceworker-companion.js.erb")
