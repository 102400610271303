import consumer from "./consumer"

const checklistChannel = consumer.subscriptions.create("ChecklistStatusChannel", {
  connected() {
    console.log("Connected to the checklist!");
  },

  disconnected() {

  },

  received(data) {
    if (window.location.host.split('.')[0] == data.subdomain) {
      $('#checklist_item_' + data.item.id).html(data.html);
      $('[data-toggle="tooltip"]').tooltip();
    }
  }
});
