export default class extends Controller {
  static targets = ['form', 'status', 'modal', 'select', 'tile']
  static values = {
    url: String
  }

  save() {
    this.element.requestSubmit()
  }

  updateStatus() {
    this.formTarget.requestSubmit()
  }

  submitEnd() {
    if (event.detail.success) {
      $(this.modalTarget).modal('hide');
      let currentStatus = this.statusTarget.innerText
      this.tileTarget.classList.remove(`bg_${currentStatus.toLowerCase().replaceAll(' ', '_')}`)
      this.statusTarget.innerText = this.selectTarget.options[this.selectTarget.selectedIndex].text;
      this.tileTarget.classList.add(`bg_${this.selectTarget.value}`)
    }
  }

  changeDate(){
    Turbo.visit(`${this.urlValue}/${event.currentTarget.value}`)
  }
}
