export default class extends Controller {
  static targets = [ "input" ]

  update() {
    let checkbox = this.inputTarget
    let confirmation = (checkbox.id == 'change-status') ? this.confirmStatus(checkbox) : this.confirmProgress(checkbox)
    if (confirmation) {
      $.ajax({
        type: "POST",
        url: checkbox.dataset.url
      })
    } else {
      checkbox.checked = !checkbox.checked
    }
  }

  confirmProgress(element) {
    if (element.value == 'true') {
      return confirm("Are you sure you want to uncheck this from in-progress?");
    } else {
      return confirm("Are you sure you want to mark this as in-progress?");
    }
  }

  confirmStatus(element) {
    if (element.value == 'true') {
      return confirm("Are you sure you want to re open this?");
    } else {
      return confirm("Are you sure you want to mark this as completed?");
    }
  }
}
