export default class extends Controller {

  connect() {
    this.element.draggable = true;
    this.element.addEventListener('dragstart', this.startDrag.bind(this));
  }

  startDrag(event) {
     const draggingItemIdValue =  this.element.querySelector('[data-item_id]').getAttribute('data-item_id');
     localStorage.setItem('currentitem', draggingItemIdValue)
  }

  acceptCard(event) {
      event.preventDefault();
  }

  dropCard(event) {
    const draggedItemId = localStorage.getItem('currentitem') // Retrieve the ID of the dragged item
    const droppedItemId = event.target.closest('.draggable-item').getAttribute('data-item_id');
    const positionId = event.target.closest('.draggable-item').getAttribute('data-position_id');
    const hotelId = event.target.closest('.draggable-item').getAttribute('data-hotel_id');
    const checklistId = event.target.closest('.draggable-item').getAttribute('data-checklist_id');
    const url = `/admin/hotels/${hotelId}/checklist_templates/${checklistId}/update_position`;

    const data = {
        draggedItemId,
        droppedItemId,
        positionId
    };

    fetch(url, {
      method: "POST",
      headers: {
           'Content-Type': 'application/json'
       },
       body: JSON.stringify(data)
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        Turbo.visit(window.location.href);
    })
  }
}
