import consumer from "./consumer"

const chatRoomChannel = consumer.subscriptions.create("ReadGuestNotificationsChannel", {
  connected() {
    console.log("Connected to the chat room!");
  },

  disconnected() {

  },

  received(data) {
    if ($('body').data('hotel-id') === data.guest.hotel_id) {
      $('#guest_data_' + data.guest.id + ' .badge ').addClass('d-none').html('0');
      $('#guest_data_' + data.guest.id).removeClass('bg-success text-white');
      $('#left_nev_messages' + ' .badge ').html(data.unread_count);
      if (data.checkout_guests_message_count === 0) {
        $('#check_out_guest_messages' + ' .badge ').addClass('d-none');
      }
      if (data.unread_count === 0) {
        $('#left_nev_messages' + ' .badge ').addClass('d-none');
      }
      $('#guest_profile_' + data.guest.id + ' .read_by_user_name ').html(data.user_by_read);
      if (parseInt($('#left_nev_messages' + ' .badge ').html()) > 0) {
        $.titleAlert(parseInt($('#left_nev_messages' + ' .badge ').html()) + " " + "New Messages", {
          stopOnFocus: false,
          interval: 700
        });
      } else {
        $.titleAlert('GuestEQ');
        resetTitleBar();
      }
      function resetTitleBar(){
        clearTimeout($.titleAlert._intervalToken)
      }
    }
  }
});
