export default class extends Controller{
  static targets = ["card"]

  initialize(){
    this.observer(this.cardTargets[this.cardTargets.length - 1])
  }

  observer(observeTarget) {
    const observer = new IntersectionObserver(entries => {
      var lastCard = entries[0]
      if (lastCard.isIntersecting) {
        observer.unobserve(lastCard.target);
        this.loadMore()
      } else {
        return
      }
    })
    observer.observe(observeTarget)
  }

  loadMore() {
    var next = document.querySelector('[rel=next]', this.paginationValue)
    if (!next) return;

    var href = next.href
    fetch(href, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html))
  }
}
