export default class extends Controller {
  static targets = ['inspectionDIv', 'submitButtonDIv', 'roomInspected', 'attachment', 'attachment_label']

   toggle(event) {
    const currentStarId = parseInt(event.currentTarget.id.split('-')[1]);

    // Iterate through all stars up to and including the current one
    for (let i = 1; i <= currentStarId; i++) {
        const starElement = document.getElementById('star-' + i);
        const checkbox = document.getElementById('checkbox-star-' + i);

        // Add 'mdi-star' and remove 'mdi-star-outline' for these stars
        document.getElementById('rate-star-' + i).classList.add('mdi-star');
        document.getElementById('rate-star-' + i).classList.remove('mdi-star-outline');

        // Check the corresponding checkboxes
        checkbox.checked = true;
    }

    // Iterate through all stars after the current one to uncheck them
    for (let i = currentStarId + 1; i <= 5; i++) {
        const starElement = document.getElementById('star-' + i);
        const checkbox = document.getElementById('checkbox-star-' + i);

        // Add 'mdi-star-outline' and remove 'mdi-star' for these stars
        document.getElementById('rate-star-' + i).classList.add('mdi-star-outline');
        document.getElementById('rate-star-' + i).classList.remove('mdi-star');

        // Uncheck the corresponding checkboxes
        checkbox.checked = false;
    }
  }

  toggleHskChecklist(){
    document.getElementById('rating_error').classList.add('d-none');
    if (document.querySelectorAll("input[id^='checkbox-star']:checked").length > 3){
      this.inspectionDIvTarget.classList.add('d-none')
    }else{
      this.inspectionDIvTarget.classList.remove('d-none')
    }
  }

  handleImageUpload(){
    if(this.attachmentTarget.files.length == 1) {
      var file = " File"
    } else {
      var file = " Files"
    }
    this.attachment_labelTarget.textContent = this.attachmentTarget.files.length + file
  }
}
