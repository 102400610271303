export default class extends Controller {
  static targets= ['row']
  static values = {
    url: String
  }

  connect(){
    if (!this.isMobileView()) {
      if (this.urlValue) {
        this.fetchTurbo(this.urlValue)
      } else {
        this.rowTargets[0].click()
      }
    }
  }

  isMobileView() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent) || window.matchMedia('(display-mode: standalone)').matches
  }

  showDetails(){
    var selected = document.querySelector('.selected')
    if (selected) {
      selected.classList.remove("selected", "bg-light")
    }
    event.currentTarget.classList.add("selected", "bg-light");
    var url = event.params.url
    if (this.isMobileView()) {
      url = `${url}?view-mode=Detached`
    }
    this.fetchTurbo(url)
  }

  fetchTurbo(url){
    fetch(url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html))
  }
}
