$(document).on('ready turbolinks:load', function() {
  window.audible_sound = false;
  resetTitleBar()
  guest_text_audible_sound();
});

function guest_text_audible_sound(){
  if(parseInt($('#left_nev_messages' + ' .badge ').html()) > 0) {
    window.audible_sound = true;
    set_audio_notification()
    set_title_alert()
  }
  else {
    setDefaultTitleBar()
  }
}

function set_audio_notification() {
  setInterval(function(){
    if(parseInt($('#left_nev_messages' + ' .badge ').html()) > 0)
      if (window.matchMedia('(display-mode: standalone)').matches){
        console.log('This is a PWA');
        document.getElementById("myAudio").disconnect(); // Disconnect the audio source
      } else {
        console.log('This is not a PWA');
        document.getElementById("myAudio").play(); // Start playing the audio
      }
  }, 60000);
}

function set_title_alert() {
  $.titleAlert(parseInt($('#left_nev_messages' + ' .badge ').html()) + " " + "New Messages", {
    stopOnFocus: false,
    interval: 700
  });
}

function setDefaultTitleBar() {
  $.titleAlert('GuestEQ')
  resetTitleBar()
}

function resetTitleBar() {
  clearTimeout($.titleAlert._intervalToken)
}

function addEmoji() {
  $(".apply-emoji").emojioneArea({
    filtersPosition: "bottom",
    tones: false,
    autocomplete: false
  });
}

function infiniteScroll(id) {
  var scroll_card = $('.simplebar-content-wrapper', "#" + id)
  scroll_card.each(function(){
    $( this ).scroll(function(){
      var condition = $(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight - 10
      var url = $('.pagination a.next_page', $( this )).attr('href')
      if(url && condition) {
        $('.pagination', $( this )).html('loading')
        $.getScript(url);
      }
    })
  })
}

function mentionUser(id){
  var users = {}
  var selected_users = []
  $.get("/mentions.json", function(data){
    users = data
  })
  .fail(function(d){
    users = {}
  })
  .always(function(){
    mention.initializeTribute(document.getElementById(id), users);
  })
}

function selectEmoji(button_id, text_area_id){
  const button = document.querySelector('#' + button_id);
  const picker = new EmojiButton({
    position: 'left-end',
    showAnimation: true,
    autoHide: true,
    showPreview: true,
    showSearch: true,
    showRecents: true,
    showVariants: true,
    showCategoryButtons: true
  });

  picker.on('emoji', emoji => {
    document.querySelector('#' + text_area_id).value += emoji["emoji"]
  });

  button.addEventListener('click', () => {
    picker.pickerVisible ? picker.hidePicker() : picker.showPicker(button);
  });
}

function notificationToaster(notification) {
  $.toast({
    text: notification,
    showHideTransition: 'fade',
    allowToastClose: true,
    hideAfter: 5000,
    stack: 5,
    position: 'top-right',

    bgColor: '#0acf97',
    textColor: '#ffffff',
    textAlign: 'left',
    loader: true,
    loaderBg: '#9EC600',
  });
}

function errorNotificationToaster(notification) {
  $.toast({
    text: notification,
    showHideTransition: 'fade',
    allowToastClose: true,
    hideAfter: 5000,
    stack: 5,
    position: 'top-right',

    bgColor: '#eb96a7',
    textColor: '#ffffff',
    textAlign: 'left',
    loader: true,
    loaderBg: '#9EC600',
  });
}

function sendComment(textarea_id, submit_id){
  $('#' + textarea_id).keydown(function(e) {
    if (e.which == 13 || e.keyCode == 13) {
      e.preventDefault();
      var text_value = $(e.target).val();
      if (text_value.includes("@") && text_value.split(" ").length == 1) {
        return false;
      } else if (text_value.split(" ")[text_value.split(" ").length - 1].includes("@")) {
        return false;
      } else {
        $('#' + submit_id).click();
      }
    }
  });
}

function showAttachedFileName(text_field_id){
  var fileName = $.map($("#comment_attachment").prop("files"), function(val) { return val.name; });
  for (var i = 0; i < fileName.length; i++) {
    fileName[i]
    var original_filename = fileName[i].split('\\').pop();
    $('#show_comment_file').append("<div>" + original_filename + "</div>");
  }
  $("#comment_content_" + text_field_id).focus();
}

function showReplyAttachment(comment_id) {
  $("#show_reply_" + comment_id +"_attachment").html(getFileNames())
  $("#hotel-chat-comment-reply_" + comment_id).focus();
}

function getFileNames() {
  let files = event.target.files
  let filename = files.item(0).name
  if (files.length > 1) {
    filename = `${files.item(0).name} ... & ${files.length - 1} more`
  }
  return filename;
}

function openReplyInputField(comment_id){
  $('#hotel_chat_comment_reply_' + comment_id).toggleClass('d-none')
}

export default {
  guest_text_audible_sound,
  set_audio_notification,
  set_title_alert,
  setDefaultTitleBar,
  resetTitleBar,
  addEmoji,
  infiniteScroll,
  mentionUser,
  selectEmoji,
  notificationToaster,
  errorNotificationToaster,
  sendComment,
  showAttachedFileName,
  showReplyAttachment,
  openReplyInputField,
  getFileNames
};
