export default class extends Controller {
  connect(){
    this.element.onload = function(){
      this.style.height = `${this.contentDocument.body.scrollHeight}px`;
      this.contentDocument.body.style.width = '100%'
      this.contentDocument.body.style.padding = '0px'
      this.contentDocument.body.style.margin = '0px'
    }
  }
}
