function removeTribute(fieldTarget) {
  var tribute = new Tribute({
    allowSpace: true,
    lookup: 'first_name',
    values: Object.values(window[fieldTarget.id]).sort(sortBy.bind(this, 'first_name'))
  })
  tribute.detach(fieldTarget);
}

function initializeTribute(fieldTarget) {
  var tribute = new Tribute({
    allowSpace: true,
    lookup: 'first_name',
    values: Object.values(window[fieldTarget.id]).sort(sortBy.bind(this, 'first_name'))
  })

  tribute.attach(fieldTarget);
  fieldTarget.addEventListener("tribute-replaced", function (event) {
    replaced(event, fieldTarget.id)
  })
}

function sortBy(value, a, b) {
  var x = a[value].toLowerCase();
  var y = b[value].toLowerCase();
  return x < y ? -1 : x > y ? 1 : 0;
}

function replaced(e, id) {
  window[id + '_selected_users'].push(e.detail.item.original)
}

function message_in_format(element) {
  let selected_users = window[element.id + '_selected_users']
  let msg = element.value
  if (selected_users){
    selected_users.forEach(function (user) {
      if(msg.includes(`@${user.first_name}`)){
        msg = msg.replace(`@${user.first_name}`, `${user.content}`);
      }
    });
  }
  return msg;
}

function init(element, path, selected_user_ids = [], post_type = '') {
  window[element.id + '_selected_users'] = [];
  $.get(path, post_type, function(data){
    window[element.id] = data
  })
  .fail(function(d){
    window[element.id] = {}
  })
  .always(function(){
    if (selected_user_ids.length > 0) {
      selected_users(selected_user_ids, element);
    }
    mention.initializeTribute(element);
  })
}

function selected_users(selected_user_ids, element) {
  selected_user_ids.forEach(function (id) {
    window[element.id + '_selected_users'].push(window[element.id][id])
  })
}

function setCommentBody() {
  let form = event.target
  let comment_element = form.querySelector('.visible-text-input')

  if (typeof window[comment_element.id + '_selected_users'] !== 'undefined' && window[comment_element.id + '_selected_users'].length) {
    form.querySelector('.mentioned_user_list').value = JSON.stringify( window[comment_element.id + '_selected_users'].map(e => e.id) )
  }

  form.querySelector('.hidden-text-input').value = mention.message_in_format(comment_element)

  form.querySelector('.submit-icon').disabled = true
  form.querySelector('.submit-button').disabled = true
  setTimeout(function(){
    form.querySelector('.submit-icon').disabled = false
    form.querySelector('.submit-button').disabled = false
  },3000);
}

export default {
  initializeTribute,
  message_in_format,
  removeTribute,
  init,
  setCommentBody
};
