export default class extends Controller {
  static targets = [ 'popover', 'scroll' ]

  connect(){
    $(this.popoverTarget).popover({
      container: 'body',
      trigger: 'hover',
      placement: 'bottom'
    })
    var element = this.scrollTarget
    setTimeout(function() {
      var scroller = element.closest('.simplebar-content-wrapper')
      if (scroller) {
        scroller.scrollTop = scroller.scrollHeight
      }
    }, 25)
  }
}
