export default class extends Controller {
  static targets = ['closeButton']

  connect() {
    if (Notification.permission !== "granted") {
      this.element.classList.remove('d-none')
    }
  }

  async allowWebpushNotifications(){
    const allowNotificationElement = event.currentTarget
    const result = await window.Notification.requestPermission()

    if (result === "granted") {
      navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
        serviceWorkerRegistration.pushManager.subscribe({
          applicationServerKey: new Uint8Array([4, 101, 42, 3, 233, 148, 238, 123, 104, 137, 128, 45, 241, 129, 34, 192, 137, 145, 180, 140, 152, 191, 108, 76, 144, 116, 192, 208, 145, 117, 8, 211, 253, 34, 150, 78, 221, 79, 221, 124, 64, 26, 23, 188, 191, 67, 209, 192, 241, 52, 234, 151, 103, 133, 10, 142, 45, 253, 102, 60, 116, 78, 123, 146, 240]),
          userVisibleOnly: true,
        }).then(async function(data){
          await fetch('/webpush_subscriptions',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
          }).then(function(r) {
            r.json()
            allowNotificationElement.classList.add('d-none')
          })
        });
      })
    }
  }
}
