export default class extends Controller {
  static targets = ['group', 'newGroup', 'brand', 'newBrand']

  renderForm(){
    event.preventDefault()
    this.url = this.element.href
    fetch(this.url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html))
  }

  removeRoom(){
    event.preventDefault()
    this.confirmation = this.confirmRoomRemoval()
    if (this.confirmation) {
      this.element.requestSubmit();
    }
  }

  confirmRoomRemoval(){
    return confirm("Are you sure, you want to remove this room?");
  }

  addGroup(){
    if (event.currentTarget.value == 'Add New Group') {
      this.groupTarget.classList.remove('d-none')
    } else {
      this.newGroupTarget.value = ''
      this.groupTarget.classList.add('d-none')
    }
  }

  addBrand(){
    if (event.currentTarget.value == 'Add New Brand') {
      this.brandTarget.classList.remove('d-none')
    } else {
      this.newBrandTarget.value = ''
      this.brandTarget.classList.add('d-none')
    }
  }
}
