export default class extends Controller{
  static targets = [ 'body', 'emoji' ]
  static values = {
    url: String,
    type: String
  }

  connect(){
    mention.init(this.bodyTarget, this.urlValue, [], `post_type=${this.typeValue}`)
    common.selectEmoji(this.emojiTarget.id, this.bodyTarget.id)
  }
}
