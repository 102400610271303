export default class extends Controller{
  static targets = [ 'checkbox', 'dropdown']

  connect(){
    this.lastchecked = 'none';
  }

  init(){
    if (event.currentTarget.checked) {
      this.handleShift()
      this.dropdownTarget.classList.remove('d-none')
    }else{
      this.dropdownTarget.classList.add('d-none')
    }
  }

  handleShift() {
    if (event.shiftKey) {
      let markChecked = false;
      this.checkboxTargets.forEach(input => {
        if (input === event.currentTarget || input === this.lastchecked) {
          markChecked = !markChecked
        }
        if (markChecked) {
          input.checked = true
        }
    	});
    }
    this.lastchecked = event.currentTarget
  }

  beforeSubmit(){
    let ids = [];
    this.checkboxTargets.forEach(input => {
      if (input.checked) {
        ids.push(input.value)
      }
    });
    event.currentTarget.parentElement.querySelector('#selected-ids').value = JSON.stringify(ids);
  }

  submitEnd() {
    if (event.detail.success) {
      $(event.currentTarget).modal('hide');
      this.dropdownTarget.classList.add('d-none')
    }
  }
}
