export default class extends Controller {
  static targets = ['closeButton']

  connect() {
    if (this.shouldDisplay()) {
      this.show()
    }
  }

  shouldDisplay() {
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const webkit = !!ua.match(/WebKit/i);
    const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
    const iOSChrome = iOS && webkit && ua.match(/CriOS/i);

    return (iOSSafari || iOSChrome) && !navigator.standalone
  }

  show() {
    this.element.classList.remove('hidden')
  }

  hide(e) {
    if (e) { e.preventDefault() }

    this.element.classList.add('hidden')
  }
}
