export default class extends Controller {
  static targets= ['show', 'copy', 'reply', 'link', 'urlImageLink']

  fetchTurbo(url){
    fetch(url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html))
  }

  showAllComments(){
    event.preventDefault()
    if (this.showTarget.children.length > 0) {
      this.showTarget.classList.toggle('d-none');
    } else {
      this.fetchTurbo(event.currentTarget.href)
    }
  }

  copy(){
    var clipboard = navigator.clipboard;
    if (clipboard == undefined) {
      console.log('clipboard is undefined. This requires a secure origin — either HTTPS or localhost');
    } else {
      clipboard.writeText(this.copyTarget.innerText).then(function() {
        console.log('Copied to clipboard successfully!');
      }, function() {
        console.error('Unable to write to clipboard. :-(');
      });
    }
  }

  updatePostForm(){
    this.fetchTurbo(`${window.location.origin}/hotel_chats/new?channel_id=${this.element.value}`)
  }

  newPostForm(){
    event.preventDefault();
    this.element.parentElement.querySelector('.active').classList.remove('active')
    this.fetchTurbo(this.element.href)
  }

  removeModel(){
    var modalElement = document.getElementById('layout-modal-window');
    if (modalElement) {
      modalElement.classList.remove('show');
      var backdropElement = document.querySelector('.modal-backdrop');
      if (backdropElement) {
        backdropElement.parentNode.removeChild(backdropElement);
      }
    }
  }

}
