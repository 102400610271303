export default class extends Controller {
  static values = { text: String };

  connect() {
    var element = this.element
    if (this.hasValidUrl(this.textValue)) {
      fetch('/link_preview', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          text: this.textValue
        })
      })
      .then((r) => r.text())
      .then((html) => {
        element.innerHTML = html
      });
    }
  }

  hasValidUrl(text){
    return (text.includes('https://') || text.includes('http://'))
  }
}
