export default class extends Controller {
  static targets = ['previewItem', 'fileInput' ]

  imgUrl(){
    var output = this.userImageTarget;
    var link = output.src = URL.createObjectURL(event.target.files[0]);
    this.imgLinkTarget.classList.remove('d-none')
    this.imgLinkTarget.href = link
  }

  listAttached(){
    var files = this.fileInputTarget.files
    var previewElement = ""
    for (let i = 0; i < files.length; i++) {
      previewElement += this.createPreviewEelment(files[i], i)
    }
    this.previewItemTarget.innerHTML = previewElement
  }

  remove(){
    var dt = new DataTransfer()
    var index = event.currentTarget.dataset.index
    var files = this.fileInputTarget.files
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      if (index !== i.toString()) {
        dt.items.add(file)
      }
    }
    this.fileInputTarget.files = dt.files
    this.listAttached()
  }

  createPreviewEelment(file, index){
    var preview = ''
    if (['image/jpeg', 'image/png'].includes(file.type)) {
      preview = `
        <img src="${URL.createObjectURL(file)}">
      `
    } else {
      preview = `
        <div class="d-flex align-items-center justify-content-center">
          <i class="mdi mdi-file-document-outline mdi-48px"></i>
        </div>
      `
    }
    var element = `
      <div class="attachment-preview">
        <div class='remove' data-index='${index}' data-action="click->attachment#remove">&times;</div>
          ${preview}
          <div class='name text-center'>${file.name}</div>
      </div>
    `
    return element;
  }
}
