export default class extends Controller {
  static targets = ['selectHotel'];

  addForm(event) {
    this.validatePassword(event);
    const form = event.target.closest(".card-body").querySelector("form");
    if (form.reportValidity()) {
      const container = document.getElementById("additional-users-container");
      const userFormTemplate = document.getElementById('user_form_0').innerHTML;

      const divId = "user_form_" + (container.children.length + 1);

      const newUserForm = document.createElement("div");

      newUserForm.id = divId;
      newUserForm.innerHTML = userFormTemplate;
      container.appendChild(newUserForm);
    }
  }

  additem(event) {
    const container = document.getElementById("items-container");
    const checklistFormTemplate = document.getElementById('template-form').innerHTML;
    const divId = "template-form-" + (container.children.length + 1);
    const newTemplateForm = document.createElement("div");
    newTemplateForm.id = divId;
    newTemplateForm.innerHTML = checklistFormTemplate;
    container.appendChild(newTemplateForm);
  }

  removeitem(event) {
    const remove_item = event.currentTarget.closest("div[id^='template-form']");
    remove_item.remove();
  }

  submitPreviewForm(event) {
    event.preventDefault();
    const form = event.target.closest(".card-body").querySelector("form");

    if (form.reportValidity()) {
      form.submit();
    }
  }

  validatePassword(event){
    const container = document.getElementById("additional-users-container");
    var total_form_count = container.children.length
    const form = event.target.closest(".card-body").querySelector("form");
    for (var i = 0; i <= total_form_count; i++) {
      const userForm = form.querySelector("#user_form_" + i);
      var password = userForm.querySelector('#user__password')
      var confirm_password = userForm.querySelector('#user__password_confirmation')
      if(password.value != confirm_password.value) {
        confirm_password.setCustomValidity("Passwords Don't Match");
      } else {
        confirm_password.setCustomValidity('');
      }
    }
  }

  getRolesModal() {
  event.preventDefault();
  const hotel_id = this.selectHotelTarget.value;
  const user_id = document.getElementById('user_id').value;

  if (hotel_id) {
    fetch(`/admin/users/${user_id}/new_hotel?hotel_id=${hotel_id}`, {
      method: 'POST'
    })
    .then(response => response.text())
    .then(html => {
      document.getElementById('add-new-hotel-form').innerHTML = html;
    })
    .catch(error => {
      console.error('Error:', error);
    });
  } else {
    document.getElementById('add-new-hotel-form').innerHTML = '';
  }
}
}
