import consumer from "./consumer"

const managerNoteChannel = consumer.subscriptions.create("ManagerNotesNotificationsChannel", {
  connected() {
    console.log("Connected to the manager note!");
  },

  disconnected() {

  },

  received(data) {
    if (window.location.host.split('.')[0] == data.subdomain) {
      if (window.location.pathname != "/mod_reports"){
        var manager_notes_count;
        manager_notes_count = parseInt($('#manager_notes_count' + ' .badge ').html()) + 1;
        $('#manager_notes_count' + ' .badge ' ).removeClass('d-none').html(manager_notes_count);
      }
    }
  }
});
