export default class extends Controller {
  static targets = ['pmcycletype', 'startdate', 'enddate', 'daterange', 'pmfilter',
                    'clearfilter']

  updateDate(){
    const pm_cycle_type = this.pmcycletypeTarget.value;
    const startDateValue = this.startdateTarget.value;
    var total_weeks;

    if (pm_cycle_type === 'quarterly') {
      total_weeks = 13;
    } else if (pm_cycle_type === 'semi_annualy') {
      total_weeks = 26;
    } else if (pm_cycle_type === 'annualy') {
      total_weeks = 52;
    } else {
      total_weeks = 0;
    }


    if (startDateValue && pm_cycle_type){
      const startDate = new Date(startDateValue);
      const endDate = new Date(startDate.getTime() + total_weeks * 7 * 24 * 60 * 60 * 1000);

      this.enddateTarget.value = endDate.toISOString().split("T")[0]
    }
  }
  showDateSelector(){
    if (event.currentTarget.dataset.value ==='custom_date' || event.currentTarget.value === 'custom_date'){
      this.daterangeTarget.classList.remove('d-none')
    }
    else{
      this.daterangeTarget.classList.add('d-none')
    }
  }

  showPmFilter(){
    this.pmfilterTarget.classList.remove('d-none')
    this.clearfilterTarget.classList.remove('d-none')
  }

  hideModal(){
    var modalElement = document.getElementById('popupModal');
    if (modalElement) {
      modalElement.classList.remove('show');
      var backdropElement = document.querySelector('.modal-backdrop');
      if (backdropElement) {
        backdropElement.parentNode.removeChild(backdropElement);
      }
    }
  }

  hideRoomModal(){
    var roomId = event.target.dataset.roomId;
    var modalElement = document.getElementById('roomModal' + roomId);
    if (modalElement) {
      modalElement.classList.remove('show');
      var backdropElement = document.querySelector('.modal-backdrop');
      if (backdropElement) {
        backdropElement.parentNode.removeChild(backdropElement);
      }
    }
  }

  printContent(event) {
    event.preventDefault();
    window.print();
  }
}
