export default class extends Controller {
  static targets = [ "input", "linkPreview" ]
  static values = { url: String, channel: String };

  connect(){
    this.timer = ''
    this.timeoutValue = 100;
    var url = this.urlValue
    var channel = `${this.channelValue}Users`
    this.tribute = new Tribute({
      lookup: 'first_name',
      fillAttr: 'first_name',
      values: function (text, cb) {
        if (window[channel]) {
          cb(window[channel])
        } else {
          fetch(url)
          .then(response => response.json())
          .then((data) => {
            window[channel] = data
            cb(data)
          })
          .catch((error) => cb([]))
        }
      }
    });
    this.tribute.attach(this.inputTarget);
    this.SelectedUsers = []
  }

  UpdateSelectedUsers(){
    this.SelectedUsers.push(event.detail.item.original)
  }

  handleKeyPress() {
    window.clearTimeout(this.timer);
  }

  handleKeyUp() {
    window.clearTimeout(this.timer);
    var text = event.currentTarget.value
    var keyCode = event.keyCode
    this.timer = window.setTimeout(() => {
      this.previewUrl(text)
    }, this.timeoutValue);
  }

  submit(){
    var text = this.inputTarget.value
    if (this.SelectedUsers.length > 0) {
      this.SelectedUsers.forEach((user) => {
        if(text.includes(`@${user.first_name}`)){
          text = text.replace(`@${user.first_name}`, `${user.content}`);
        }
      });
    }
    this.inputTarget.value = text
  }

  previewUrl(text){
    var hasValidUrl = text.includes('https://') || text.includes('http://')
    if (hasValidUrl && this.linkPreviewTarget.children.length == 0) {
      var element = `
        <div class="mt-1 mb-1"
        data-controller="link-preview"
        data-link-preview-text-value="${text}">
        </div>
      `
      this.linkPreviewTarget.innerHTML = element
    } else if (!hasValidUrl) {
      this.linkPreviewTarget.innerHTML = ""
    }
  }
}
