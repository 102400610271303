export default class extends Controller {
  static targets = ['checkedroom', 'form', 'modalBody', 'select', 'status']

  initialize() {
    this.selectedRoomIds = [];
  }

  toggleSideNavbar(){
    if (document.body.getAttribute('data-leftbar-compact-mode')) {
      document.body.removeAttribute('data-leftbar-compact-mode');
    } else {
      document.body.setAttribute('data-leftbar-compact-mode', 'condensed');
    }
  }

  showSideNavbar(){
    document.body.removeAttribute('data-leftbar-compact-mode');
    document.body.classList.toggle("sidebar-enable");
  }

  changeRoomColor(){
    var room = 'room-card-'+ event.currentTarget.value
    if (event.currentTarget.checked === true){
      document.getElementById(room).classList.add('bg-room-color');
    }else{
      document.getElementById(room).classList.remove('bg-room-color');
    }
  }

  selectAll(event) {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    if (event.currentTarget.checked) {
      this.checkAll(checkboxes);
    } else {
      this.uncheckAll(checkboxes);
    }
  }

  checkAll(checkboxes) {
    checkboxes.forEach(function(checkbox) {
      checkbox.checked = true;
    });
  }

  uncheckAll(checkboxes) {
    checkboxes.forEach(function(checkbox) {
      checkbox.checked = false;
    });
  }

  checkedPm(){
    const checkboxes = document.querySelectorAll('input[type="checkbox"]:not(#selected_all)');
    let allChecked = true;

    checkboxes.forEach(function(checkbox) {
      if (!checkbox.checked) {
        allChecked = false;
        return;
      }
    });

    if (allChecked) {
      document.getElementById('selected_all').checked = true
    } else {
      document.getElementById('selected_all').checked = false
    }
  }

  submitPmForm() {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    let atLeastOneChecked = false;

    checkboxes.forEach(function(checkbox) {
      if (checkbox.checked) {
        atLeastOneChecked = true;
        return;
      }
    });

    if (atLeastOneChecked) {
      document.getElementById('pm_cycles_form').requestSubmit();
      document.getElementById('checkboxValidationMessage').classList.add('d-none');
    } else {
      document.getElementById('checkboxValidationMessage').classList.remove('d-none');
    }
  }

  storeRoomId(){

    const checkbox = event.target;
    const roomId = checkbox.dataset.roomId;

    if(checkbox.checked){
    this.addRoomId(roomId);
    }
    else{
    this.removeRoomId(roomId);
    }
  }

  addRoomId(roomId) {
    if (!this.selectedRoomIds.includes(roomId)) {
      this.selectedRoomIds.push(roomId);
      console.log(`Added room ID ${roomId}. Updated selectedRoomIds:`, this.selectedRoomIds);
    }
  }

  removeRoomId(roomId) {
    this.selectedRoomIds = this.selectedRoomIds.filter(id => id !== roomId);
    console.log(`Removed room ID ${roomId}. Updated selectedRoomIds:`, this.selectedRoomIds);
  }

  sendRoomIdsToServer(event) {
    event.preventDefault();
    var checkboxes = document.querySelectorAll('input[type="checkbox"]:checked');

    const checkedCheckboxes = document.querySelectorAll('input[type="checkbox"]:checked');
    const values = Array.from(checkedCheckboxes).map(checkbox => checkbox.value);
    const params = new URLSearchParams({ room_ids: values.join(',') });

    const url = `/admin/room_details?${params.toString()}`;

    fetch(url, {
      method: 'GET',
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    })
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html));
  }
}
