export default class extends Controller {
  static targets= ['replyForm', 'reply', 'loadMoreReplyForm']

  toggleReplies(){
    event.preventDefault()

    const linkElement = event.currentTarget;
    let href = event.currentTarget.href;

    // Get the data-offset value
    let offset = event.currentTarget.getAttribute('data-offset');
    let newHref = href + (href.includes('?') ? '&' : '?') + 'offset=' + offset;
    const newOffset = parseInt(offset) + 5;

    // Set the new href value
    event.currentTarget.href = newHref;
      fetch(event.currentTarget.href, {
        headers: {
          Accept: "text/vnd.turbo-stream.html",
        },
      })
      .then(r => r.text())
      .then(html => {
        linkElement.setAttribute('data-offset', newOffset);
        Turbo.renderStreamMessage(html);
      })
  }

  showReplyForm(){
    this.replyFormTarget.classList.toggle('d-none')
    this.loadMoreReplyFormTarget.classList.add('d-none')
  }

  showReplyFormAndHideLoadMore(){
    let offset = event.currentTarget.getAttribute('data-offset');
    let total_reply = event.currentTarget.getAttribute('data-total-replis');
    let comment_id = event.currentTarget.getAttribute('data-comment-id');
    var dynamicId = 'load-more-replies-' + comment_id;
    if (parseInt(offset) >= parseInt(total_reply)){
      document.getElementById(dynamicId).classList.add('d-none')
      this.loadMoreReplyFormTarget.classList.remove('d-none')
      this.replyFormTarget.classList.add('d-none')
    }
  }
}
