import Sortable from 'sortablejs'

// Bust cache
export default class extends Controller {
  static values = {
    url: String
  }

  connect(){
    this.sortable = Sortable.create(this.element, {
      handle: '.handle',
      animation: 150,
      ghostClass: 'bg-light',
      onEnd: this.end
    })
  }

  end = (event) => {
    fetch(this.urlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: event.item.dataset.id,
        position: event.newIndex
      }),
    })
  }
}
