export default class extends Controller {
  save(){
    if (event.currentTarget.value != '') {
      this.element.requestSubmit()
    }
  }

  redirectTo(){
    if (event.currentTarget.value != '') {
      Turbo.visit(event.currentTarget.value)
    }
  }
}
