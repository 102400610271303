if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/serviceworker.js', { scope: './' }).then(function(reg) {
    console.log('[Companion]', 'Service worker registered!');

    if (Notification.permission === "granted") {
      subscribeNotifications()
    } else {
      const ua = window.navigator.userAgent;
      const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
      const webkit = !!ua.match(/WebKit/i);
      const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

      const isIos = iOSSafari && !navigator.standalone

      if (!isIos) {
        subscribeNotifications()
      }
    }
  });
} else {
  console.error('Service worker is not supported in this browser');
}

function subscribeNotifications() {
  navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
    serviceWorkerRegistration.pushManager
    .subscribe({
      userVisibleOnly: true,
      applicationServerKey: new Uint8Array([4, 101, 42, 3, 233, 148, 238, 123, 104, 137, 128, 45, 241, 129, 34, 192, 137, 145, 180, 140, 152, 191, 108, 76, 144, 116, 192, 208, 145, 117, 8, 211, 253, 34, 150, 78, 221, 79, 221, 124, 64, 26, 23, 188, 191, 67, 209, 192, 241, 52, 234, 151, 103, 133, 10, 142, 45, 253, 102, 60, 116, 78, 123, 146, 240])
    }).then(async function(data){
      await fetch('/webpush_subscriptions',{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      }).then(r => r.json())
    });
  });
}
