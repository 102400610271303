export default class extends Controller {

  connect() {
   this.showModal();
 }

 showModal() {
   const modal = new bootstrap.Modal(this.element);
   modal.show();
 }
}
