import consumer from "./consumer"
const chatRoomChannel = consumer.subscriptions.create("TextNotificationsChannel", {
  connected() {
    console.log("Connected to the chat room!");
  },

  disconnected() {

  },

  received(data) {
    var currentHotelId = $('body').data('hotel-id');
      if (currentHotelId === data.guest.hotel_id) {
      append_new_message_in_chat(data);
      if (data.direction === "inbound") {
        perform_inbound_message_operations(data);
      }
    }

    function perform_inbound_message_operations(data) {
      update_new_message_on_chat_index_page(data);
      update_guest_replies_count_on_index_page(data);
      update_checkout_messages_count(data);
      update_count_on_left_nav(data);
      read_new_message_if_on_chat_page(data);
      if (!window.matchMedia('(display-mode: standalone)').matches) {
        set_title_alert_for_new_message(data);
        send_audio_notification();
      }
    }

    function append_new_message_in_chat(data) {
      $('#text_message_section_' + data.guest.id).append(data.html);
      if (window.location.pathname == "/guest_chats") {
        $(".conversation-list .simplebar-content-wrapper").scrollTop($(".conversation-list .simplebar-content-wrapper")[0].scrollHeight)
      }
    }

    function update_new_message_on_chat_index_page(data) {
      var new_messages_count;
      new_messages_count = parseInt($('#guest_data_' + data.guest.id + ' .badge ').html()) + 1;
      $('#guest_data_' + data.guest.id + ' .badge ').removeClass('d-none').html(new_messages_count);
      $('#guest_data_' + data.guest.id).addClass('bg-success text-white');
      $('#guest_data_' + data.guest.id).prependTo('#text_message_body');
    }

    function update_guest_replies_count_on_index_page(data) {
      var guest_replies_count;
      guest_replies_count = parseInt($('#guest_profile_' + data.guest.id + ' .count_replies ').html()) + 1;
      $('#guest_profile_' + data.guest.id + ' .count_replies ').html(guest_replies_count);
    }
    function update_checkout_messages_count(data) {
      if (data.checkout_guests_message_count > 0) {
        $('#check_out_guest_messages' + ' .badge ').removeClass('d-none').html(data.checkout_guests_message_count);
      }
    }

    function update_count_on_left_nav(data) {
      var left_nev_messages_count;
      if (window.location.pathname !== "/guest_chats/" + data.guest.id) {
        left_nev_messages_count = parseInt($('#left_nev_messages' + ' .badge ').html()) + 1;
        $('#left_nev_messages' + ' .badge ').removeClass('d-none').html(left_nev_messages_count);
      }
    }

    function read_new_message_if_on_chat_page(data) {
      if (window.location.pathname === "/guest_chats/" + data.guest.id) {
        return $.ajax({
          type: 'GET',
          url: '/read_text_notification',
          data: {
            id: data.guest.id
          }
        });
      }
    }

    function set_title_alert_for_new_message(data) {
      var text_titleflg;
      if (data.text_message) {
        text_titleflg = data.guest.first_name + " " + data.guest.last_name + " - " + data.text_message;
        $.titleAlert(text_titleflg, {
          stopOnFocus: false,
          interval: 700
        });
      }
    }

    function send_audio_notification() {
      if (parseInt($('#left_nev_messages' + ' .badge ').html()) > 0) {
        document.getElementById("myAudio").play();
        if (!window.audible_sound) {
          if(parseInt($('#left_nev_messages' + ' .badge ').html()) > 0) {
            window.audible_sound = true;
            set_audio_notification()
            set_title_alert()
          }
          else {
            setDefaultTitleBar()
          }
        }
      }
    }
    function set_audio_notification(){
      setInterval(function(){
        if(parseInt($('#left_nev_messages' + ' .badge ').html()) > 0)
          document.getElementById("myAudio").play();
      }, 60000);
    }
    function set_title_alert(){
      $.titleAlert(parseInt($('#left_nev_messages' + ' .badge ').html()) + " " + "New Messages", {
        stopOnFocus: false,
        interval: 700
      });
    }
    function setDefaultTitleBar(){
      $.titleAlert('GuestEQ')
      resetTitleBar()
    }
    function resetTitleBar() {
      clearTimeout($.titleAlert._intervalToken)
    }
  }
});
